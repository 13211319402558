
	bcore.templates['activity_row'] = `<div class="row activity-row" data-creation="{%= creation.split(" ")[0] + " 00:00:00" %}">
	<div class="col-xs-3 text-right activity-date"><span class="{%= date_class %}">
		{%= date_sep || "" %}</span></div>
	<div class="col-xs-9 activity-message"
		data-doctype="{%= encodeURIComponent(reference_doctype) %}"
		data-docname="{%= encodeURIComponent(reference_name) %}"
		data-link-doctype="{{ encodeURIComponent(link_doctype) }}"
		data-link-name="{{ encodeURIComponent(link_name) }}"
		title="{%= by %} / {%= bcore.datetime.str_to_user(creation) %}">
		{{ avatar }}
		<span class="small">
		{% if (feed_type==="Login") { %}
			{%= __("Logged in") %}
		{% } else if (feed_type==="Label") { %}
			{%= __("{0} {1}", ["<strong>" + subject + "</strong>", link]) %}
		{% } else if (reference_doctype && feed_type==="Comment") { %}
			{%= __("Commented on {0}: {1}", [link, "<strong>" + content + "</strong>"]) %}
		{% } else if (reference_doctype && communication_type==="Communication") { %}
			{%= __("Communicated via {0} on {1}: {2}", [__(feed_type), link, "<strong>" + subject + "</strong>"]) %}
		{% } else if (reference_doctype && !feed_type) { %}
			{%= __("Updated {0}: {1}", [link, "<strong>" + subject + "</strong>"]) %}
		{% } else if (feed_type==="Like" && reference_doctype) { %}
			{%= by %} <i class="octicon octicon-heart"></i>
			{% if (in_list(["Comment", "Communication"], reference_doctype)) { %}
				{%= content %}
			{% } else { %}
				{%= link %}
			{% } %}
		{% } else if (in_list(["Created", "Submitted", "Cancelled", "Deleted"], feed_type)) { %}
			{%= __("{0} {1}", ["<strong>" + __(feed_type) + "</strong>", feed_type==="Deleted" ? subject : link ]) %}
		{% } else if (feed_type==="Updated") { %}
			{%= __("Updated {0}: {1}", [link, "<strong>" + subject + "</strong>"]) %}
		{% } else if (feed_type==="Relinked") { %}
            		 {%= __("{0} {1} to {2}", [by, content,link]) %}
		{% } else if (reference_doctype && reference_name) { %}
			{%= __("{0}: {1}", [link, "<strong>" + content + "</strong>"]) %}
		{% } else { %}
			{%= subject %}
		{% } %}
	</span>
	</div>
</div>
`;
