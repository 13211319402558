import localforage from "localforage";

bcore.provide("bcore.utils");

export const call_if_exists = (callable, ...args) => {
	if (callable && typeof callable === "function") {
		return callable(...args);
	}
}

/**
 * A helper function that wraps a function to provide debounced repeated calls.
 * The wrapped function will only be called once if repeatedly called within its timeout window.
 * @param {function} fn The function to debounce
 * @param {*} thisArg The function scope
 * @param {number} timeout Any positive number in milliseconds to wait before calling function
 */
export const with_debounce = (fn, thisArg, timeout) => {
	const fn_map = new WeakMap();
	fn_map.set(fn, {
		resolved: false,
		promise: null,
		resolve: null,
		reject: null,
		fn,
		timeout,
		thisArg,
		last_args: [],
		timeoutId: null
	});

	return (function (...args) {
		const info = fn_map.get(fn);

		if (info.timeoutId) {
			info.last_args = null;
			clearTimeout(info.timeoutId);
			info.timeoutId = null;
		}

		// keep promise around between debounce calls to avoid breaking
		// client code that has already called function before.
		if (!info.promise || info.resolved) {
			info.promise = new Promise((resolve, reject) => {
				info.resolve = resolve;
				info.reject = reject;
			});
			info.resolved = false;
		}

		const timeoutCallback = function (fn, thisArg, args, promise, resolve, reject) {
			Promise.resolve(fn.apply(thisArg, args))
				.then((result) => {
					return resolve(result);
				})
				.catch(reject);
		}

		info.last_args = args;
		info.timeoutId = setTimeout(() => {
			timeoutCallback(info.fn, info.thisArg, info.last_args, info.promise, info.resolve, info.reject);
			info.promise = null;
			info.resolved = true;
			info.reject = null;
			info.resolve = null;
		}, info.timeout);

		return info.promise;

	}.bind(thisArg))
}

/**
 * Helper function wrapper that provides throttled call functionality.
 * @param {Function} func The functiont wrap and throttle
 * @param {object} thisArg This binding object
 * @param {number} timeout The throttle timeout
 * @returns {Function}
 */
export const with_throttle = (func, thisArg, timeout) => {
	let throttle = false;
	return (function (...args) {
		if (throttle) {
			return;
		}
		throttle = true;
		setTimeout(() => {
			throttle = false;
		}, timeout);
		return func.apply(thisArg, args);
	}).bind(thisArg);
}

export const equals = (a, b) => {
	if (a === b) {
		return true;
	}

	if (typeof a !== typeof b) {
		return false;
	}

	if (Array.isArray(a) && Array.isArray(b)) {
		return a.length === b.length && a.every((e, i) => e === b[i]);
	}

	if (a && typeof a === "object" && b && typeof b === "object") {
		const a_keys = Object.keys(a);
		const b_keys = Object.keys(b);
		return equals(a_keys, b_keys) && a_keys.every((key) => a[key] === b[key]);
	}

	return a == b;
}

export const get_object_values = (obj) => {
	return [
		...Object.values(obj),
		...Object.getOwnPropertySymbols(obj).map(s => Reflect.get(obj, s))
	];
}

// Wrap old apis with new router controller
export const api_property_wrap = (api_obj, api_name, map_to, map_to_name, can_get, can_set) => {
	const map_to_api = Reflect.get(map_to, map_to_name || api_name);

	Reflect.defineProperty(api_obj, api_name, {
		get() {
			if (can_get) {
				return Reflect.get(map_to, map_to_name || api_name);
			}

			console.trace("API GET: ", api_name, " of ", api_obj, " get is deprecated.");
			throw new Error("Deprecated API: ", api_name);
		},
		set(v) {
			if (can_set) {
				Reflect.set(map_to, map_to_name || api_name, v);
				return;
			}

			console.trace("API SET: ", api_name, " of ", api_obj, " set is deprecated.");
			throw new Error("Deprecated API: ", api_name);
		}
	});
}

export const api_wrap = (api_obj, api_name, map_to, map_to_name, warning) => {
	const fn = (...args) => {
		if (warning) {
			console.trace(warning);
		}
		return Reflect.apply(Reflect.get(map_to, map_to_name || api_name), map_to, args);
	}

	Reflect.set(api_obj, api_name, fn);
	return fn;
}

/**
 * Call a function on next tick
 * @param {function} fn 
 * @returns Promise<any>
 */
export const defer = async (fn, args = []) => {
	return new Promise((resolve, reject) => {
		setTimeout(function () {
			Promise.resolve(fn(...args)).then(resolve).catch(reject);
		}, 0);
	});
}

/**
 * Creates a de-structured promise. Meant to be used where promises are not usually passed
 * but desired for older callback apis.
 * @returns 
 */
export const promised_api = (data = null) => {
	const api = {
		_resolve: null,
		_reject: null,
		data,
		promise: null,
		on_resolve(value) {
			this.result = value;
			this._resolve(value, this);
		},
		on_reject(err) {
			this.error = err;
			this._reject(err);
		}
	}
	api.on_resolve = api.on_resolve.bind(api);
	api.on_reject = api.on_reject.bind(api);

	api.promise = new Promise((resolve, reject) => {
		api._resolve = resolve;
		api._reject = reject;
	});
	return api;
}

const _fetch_queue = [];
const _fetch_cache = new Map();
let _fetch_busy = false;
export const queue_fetch = (url, init, cache = true) => {
	if (cache && _fetch_cache.has(url)) {
		const value = _fetch_cache.get(url);
		_fetch_cache.set(url, value.clone());
		return Promise.resolve(value);
	}

	const promise = promised_api({ url, init, cache });
	_fetch_queue.push(promise);
	if (!_fetch_busy) {
		next_queued_fetch();
	}
	return promise.promise;
}
const next_queued_fetch = () => {
	const next = _fetch_queue.shift();
	if (next) {
		_fetch_busy = true;
		return fetch(next.data.url, next.data.init)
			.then((value) => {
				if (next.data.cache) {
					_fetch_cache.set(next.data.url, value.clone());
				}
				return next.on_resolve(value);
			}, next.on_reject)
			.then(next_queued_fetch)
			.then(() => _fetch_busy = false)
			.catch(err => {
				console.error("Error while fetching data: ", err);
				return next_queued_fetch();
			})
	}
}

let icon_id = 1;
export const load_icon = bcore.load_icon = (icon, cls, size, stroke) => {
	const icon_url = `/assets/bcore/icons/${icon}.svg`;
	icon_id++;
	const id = `load-icon-${icon_id}`;
	const inject_icon_data = (id, data, requeue_fn) => {
		const $container = $(`#${id}`);
		if ($container.length > 0) {
			const $svg = $(data);
			$svg.addClass(cls);
			$svg.css({
				width: size || '16px',
				height: size || '16px',
				strokeWidth: stroke || 1
			});
			$container.replaceWith($svg);
			$svg.fadeIn('fast');
		} else {
			setTimeout(requeue_fn, 1000);
		}
	}
	localforage.getItem(icon_url).then((data) => {
		if (data) {
			const update_svg = async () => {
				inject_icon_data(id, data, update_svg);
			}
			update_svg();
		} else {
			queue_fetch(icon_url).then(async (result) => {
				if (result.ok) {
					const data = await result.text();
					localforage.setItem(icon_url, data);
					const update_svg = async () => {
						inject_icon_data(id, data, update_svg);
					}
					update_svg();
				} else {
					const update_svg = async () => {
						inject_icon_data(id, `<span class="p-1 bg-semantic-error c-white round" title="Icon file ${icon_url} missing">?</span>`, update_svg);
					}
					update_svg();
				}
			}).catch((err) => {
				console.error(err);
			});
		}
	})
	return `<span id="${id}" class="d-inline-block" style="width: ${size || '16px'}; height: ${size || '16px'};"></span>`
}

export const str_hash = (str) => {
	str = str + '';
	let hash = 0;
	let length = str.length;
	for (let i = 0; i < length; i++) {
		hash = Math.imul(hash, 31) + str.charCodeAt(i);
	}
	return hash.toString(16);
}

/**
 * Internally used to do simple string replacement for useful tags in ESM template literals.
 * Only current replacement tag is `:host` which will be replaced with the current protocol + origin values.
 * @param {string} script The esm script
 */
const esm_replace = (script) => {
	const rep = {
		":host": window.location.origin
	};
	for (const [key, value] of Object.entries(rep)) {
		script = script.replace(key, value);
	}
	return script;
}

/**
 * A helper ESM template literal. Prefix this function to any string containing a javascript module to generate an in memory module. 
 */
export const esm = ({ raw }, ...vals) => {
	const raw_replace = [];
	const vals_replace = [];
	for (let i = 0; i < raw.length; i++) {
		if (typeof raw[i] === "string") {
			raw_replace.push(esm_replace(raw[i]));
		} else {
			raw_replace.push(raw[i]);
		}
	}

	for (let i = 0; i < vals.length; i++) {
		if (typeof vals[i] === "string") {
			vals_replace.push(esm_replace(vals[i]));
		} else {
			vals_replace.push(vals[i]);
		}
	}
	const script_blob = new Blob([String.raw({ raw: raw_replace }, ...vals_replace)], { type: 'text/javascript' })
	const script = URL.createObjectURL(script_blob);

	return script;
}

/**
 * Loads a bundled esm javascript module programmatically from inside and outside a module context.
 * The bundle must exist in the distribution path for this function to resolve a unique hash.
 * 
 * @param {string} url The unique url of the module to load.
 * @returns {Promise<Void>} A promise you can use to detect when the module is loaded.
 */
export const lazyLoad = async (url) => {
	let unique_path = url;
	if (config.bundles[url] != undefined) {
		unique_path = `${window.location.origin}${config.bundles[url]}`;
	}

	return await import(esm`
	const lazy_module = await import("${unique_path}");
	
	export default async function() {
		if ( typeof lazy_module === "object" && typeof lazy_module.default === "function" ) {
			return await Promise.resolve(lazy_module.default());
		}
	};
	`);
}

/**
 * Loads a module script dynamicall and calls the default export if exposed as function.
 * @param {*} script The full script to load.
 */
export const loadScript = async (script, label) => {
	const script_blob = new Blob([String.raw({ raw: esm_replace(script) })], { type: 'text/javascript' })
	const script_obj = URL.createObjectURL(script_blob);

	const module = await import(script_obj);
	if (typeof module === "object" && typeof module.default === "function") {
		await Promise.resolve(module.default());
	}
}

/**
 * Returns true if object contains a key that is of type "function"
 */
export const is_function = bcore.utils.is_function = (obj, key) => typeof Reflect.get(obj, key) === "function";

/**
 * Returns a list of methods belonging to an object including inherited ones.
 * @param {object} obj 
 */
export const get_obj_members = bcore.utils.get_methods = (obj) => {
	if (obj && obj !== Object.prototype) {
		const members = Object.getOwnPropertyNames(obj)
			.filter(name => name !== "constructor" && name.indexOf("__") == -1);
		const methods = [
			...members,
			...get_obj_members(Object.getPrototypeOf(obj))
		];
		return Array.from(new Set(methods));
	}

	return [];
}


export const shadow_mixin = (target, source) => {
	const source_members = get_obj_members(source);
	for (const key of source_members) {
		if (is_function(source, key)) {
			Reflect.set(target, key, (...args) => {
				return Reflect.apply(Reflect.get(source, key), source, args);
			});
		} else {
			Reflect.defineProperty(target, key, {
				get() {
					return Reflect.get(source, key);
				}
			})
		}
	}
	return target;
}

bcore.utils.shadow_mixin = shadow_mixin;

class ESMScriptLoader {
	modules = [];

	async load_module(url) {
		const module = await lazyLoad(url);
		if (typeof module === "object" && typeof module.default === "function") {
			this.modules.push(module.default);
		}
	}

	async run_defaults() {
		for (const handler of this.modules) {
			await Promise.resolve(handler());
		}
	}
}

/**
 * Loads a bundled esm javascript module programmatically from inside and outside a module context.
 * The bundle must exist in the distribution path for this function to resolve a unique hash.
 * 
 * @param {string} url The unique url of the module to load.
 * @returns {Promise<Void>} A promise you can use to detect when the module is loaded.
 */
bcore.lazyLoad = lazyLoad;
bcore.loadScript = loadScript;
bcore.ESMScriptLoader = ESMScriptLoader;